import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InView } from "@base";
import {
  Player,
  Youtube,
  Vimeo,
  DefaultUi,
  DefaultControls,
} from "@vime/react";

const VideoPlayer = ({ preserveAspectRatio, url, showControls, autoplay }) => {
  const player = useRef(null);
  const [iOS, setiOS] = useState(false);

  let id;

  const provider = url.match("http(s)?://(www.)?youtube|youtu.be")
    ? "youtube"
    : "vimeo";
  if (provider === "youtube") {
    if (url.match("embed")) {
      [id] = url.split(/embed\//)[1].split('"');
    } else {
      [id] = url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/);
    }
  }

  if (provider === "vimeo") {
    id = url.split("/").pop();
  }

  useEffect(() => {
    setiOS(
      ([
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)) &&
        // eslint-disable-next-line eqeqeq
        navigator.userAgent.search("Firefox") === -1 &&
        navigator.userAgent.search("FxiOS") === -1
    );
  }, []);

  return (
    <InView
      onEnter={() => {
        if (autoplay && player.current) player.current.play();
      }}
      onExit={() => {
        if (player.current) player.current.pause();
      }}
      observerOptions={{
        threshold: 0.01,
      }}
      className={classNames("w-full overflow-hidden rounded-lg", {
        "cursor-pointer": showControls,
        "pointer-events-none": !iOS && !showControls,
        "absolute inset-0 overflow-hidden": !preserveAspectRatio,
      })}
    >
      <Player
        ref={player}
        controls={showControls}
        autoplay={autoplay}
        muted={autoplay}
        loop={autoplay}
      >
        {provider === "youtube" && <Youtube videoId={id} />}
        {provider === "vimeo" && (
          <Vimeo portrait={false} byline={false} videoId={id} />
        )}
        <DefaultUi noControls={!iOS}>
          {/* We setup the default controls and pass in any options. */}
          <DefaultControls hideOnMouseLeave activeDuration={2000} />
        </DefaultUi>
      </Player>
    </InView>
  );
};

VideoPlayer.defaultProps = {
  loop: false,
  autoplay: false,
  showControls: false,
  preserveAspectRatio: true,
};

export default VideoPlayer;
